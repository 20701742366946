import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Telegram",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/telegram.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Telegram`}</h1>
    <p>{`Reply to your Telegram messages from Crisp`}</p>
    <hr></hr>
    <p>{`This integration let you Connect Crisp to a Telegram Bot.`}</p>
    <p>{`Your Telegram users are now able to reach you using their favorite app and you can reply to them using your Crisp Inbox. It's compatible with all the Crisp ecosystem, like the Bot integration, Slack or Zendesk.`}</p>
    <p>{`Some benefits of using Crisp with Telegram:`}</p>
    <ul>
      <li parentName="ul">{`Avoid distraction by keeping all your chats in Crisp`}</li>
      <li parentName="ul">{`Be more productive with the Crisp Inbox features (Knowledge Base, Shortcuts, Assignments)`}</li>
      <li parentName="ul">{`Integrate it with your team tools`}</li>
      <li parentName="ul">{`Re-target your Telegram users`}</li>
      <li parentName="ul">{`Preserves most messaging features like buttons, file-sharing, gifs and emojis`}</li>
      <li parentName="ul">{`Build a Telegram bot in few clicks with the Crisp bot feature`}</li>
      <li parentName="ul">{`Messages you receive are stored in the Crisp dashboard. Your chat history is safe.`}</li>
      <li parentName="ul">{`Use the Crisp search engine to search previous conversations`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      